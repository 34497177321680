<template>
  <aside id="group-details-modal" class="modal-window">
    <header>
      <h2>{{modalTitle}}</h2>
      <a href="#" @click.prevent="$emit('close')" class="close"><fa-icon icon="times" /></a>
    </header>

    <footer>
      <div class="controls left">
        <el-button type="danger" size="small" round @click="archive" :loading="loading" v-if="isEdit">Archive</el-button>
      </div>
      <div class="controls right">
        <el-button type="success" size="small" round @click="save" :disabled="!canSave" :loading="loading">Save</el-button>
      </div>
    </footer>

    <div class="scroll-view">

      <div class="modal-form">

        <div class="form-row">
          <div class="label">
            <label>Slack Channel</label>
            <p>Choose a channel to celebrate this group's birthdays in. This group will automatically sync with the members of its channel.</p>
          </div>

          <div class="field">
            <div class="channel-selector">
              <el-select ref="channel_selector" v-model="group.platform_channel_id" filterable placeholder="Select a channel...">
                <el-option value="CREATE_CHANNEL"><fa-icon icon="plus" size="sm" /> Create channel</el-option>

                <el-option-group label="Channels" v-if="channels !== null">
                  <el-option
                    v-for="channel in channels"
                    :key="`slack_channel_${channel.id}`"
                    :label="`#${channel.name}`"
                    :value="channel.id"
                  >
                  </el-option>
                </el-option-group>

                <template slot="empty">
                  <p class="el-select-dropdown__empty">No channels found.</p>
                </template>
              </el-select>

              <el-button circle icon="el-icon-refresh" size="mini" :loading="channelsLoading" @click="populateChannels"></el-button>
            </div>

            <p class="hint-text"><strong>Not finding your channel?</strong> Invite Cupcake by using <pre>/invite @Cupcake</pre> in the channel you want to choose.</p>
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Donation Amount</label>
            <p>Amount your organization will contribute for each birthday in this group.</p>
          </div>

          <div class="field">
            <div :class="['form-control-wrap', $fieldError($v.group.reward_amount)]">
              <div class="append-group">
                <currency-input class="form-control" v-model.number="$v.group.reward_amount.$model" :currency="team.currency_code" :precision="0" :distraction-free="false" :value-as-integer="true" :allow-negative="false" />
                <span class="to-append">{{team.currency_code}}</span>
              </div>
              <span class="form-control-error">{{$fieldErrorMessage($v.group.reward_amount, 'group.reward_amount', $options.validation_messages)}}</span>
            </div>

            <p class="hint-text"><strong>You'll receive a monthly statement for donations.</strong> Learn more about <a href="https://help.givecupcake.com/hc/en-us/articles/1500000099762-How-am-I-billed-for-birthdays-and-donations-" target="_blank" rel="noopener">how our billing works</a>.</p>
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Celebration Time</label>
            <p>Time of day birthday celebrations will be posted in this channel.</p>
          </div>

          <div class="field">
            <div class="time-selector">
              <el-select v-model="group.celebration_hour">
                <el-option
                  v-for="hr in hours"
                  :key="`hour_${hr.hour}`"
                  :label="hr.label"
                  :value="hr.hour"
                ></el-option>
              </el-select>
              <el-select v-model="group.timezone" filterable>
                <el-option
                  v-for="(tz, i) in $timezones"
                  :key="`timezone_${i}`"
                  :label="tz.label"
                  :value="tz.tzCode"
                >
                </el-option>

                <template slot="empty">
                  <p class="el-select-dropdown__empty">No timezones found.</p>
                </template>
              </el-select>
            </div>
          </div>
        </div>

      </div>

    </div>
  </aside>
</template>

<script>
import {CurrencyInput} from 'vue-currency-input'
import {required, between} from 'vuelidate/lib/validators'
export default {
  name: 'group-details-modal',
  components: {
    CurrencyInput
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
    canSave() {
      return !this.$v.$invalid;
    },
    modalTitle() {
      return this.isEdit ? 'Group Details' : 'Create Group';
    },
    channels() {
      return this.$store.state.channels;
    },
    team() {
      return this.$store.state.team;
    },
    hours() {
      return _.map(
        _.range(0, 24), (h) => {
          const amPm = h >= 12 ? 'PM' : 'AM';
          return {
            hour: h,
            label: `${(h % 12) || 12} ${amPm}`
          }
        }
      );
    }
  },
  data() {
    return {
      isEdit: false,
      channelsLoading: false,
      loading: false,
      members: null,
      group: {
        platform_channel_id: '',
        reward_amount: 1,
        celebration_hour: 10,
        timezone: 'America/New_York'
      }
    }
  },
  validations: {
    group: {
      platform_channel_id: {
        required
      },
      reward_amount: {
        required,
        between: between(1, 1000)
      }
    }
  },
  validation_messages: {
    group: {
      reward_amount: {
        between: `Donation amount must be at least $1 and no more than $1,000.`
      }
    }
  },
  watch: {
    'group.platform_channel_id': function(newVal, oldVal) {
      if( newVal == 'CREATE_CHANNEL' ) {
        this.group.platform_channel_id = '';
        this.$refs.channel_selector.blur();
        this.createChannel();
      }
    }
  },
  mounted() {
    this.populateChannels()

    if( !!this.$route.params.data ) {
      this.group = _.cloneDeep(this.$route.params.data);
      this.isEdit = true;
    }
    else if( !!this.$route.params.groupId ) {
      this.populateGroup( this.$route.params.groupId )
      this.isEdit = true;
    }
    else {
      this.timezone = this.me.timezone;
    }
  },
  methods: {
    async populateGroup(id) {
      const resp = await this.$api.Groups.get(id)
      this.group = resp;

      // const members = await this.$api.Groups.members(id)
      // this.members = members;
    },
    async archive() {
      this.$confirm('Are you sure you want to archive this group?', 'Confirm Archive', {
        confirmButtonText: `Yes`,
        cancelButtonText: `No, cancel`,
        type: 'warning'
      })
      .then(async () => {
        await this.$api.Groups.archive( this.group.id )

        this.$router.replace({
          name: 'admin-groups',
          params: {
            forceRefresh: true
          }
        })
      })
      .catch();
    },
    createChannel() {
      this.$openDialog(
        () => import('@/components/Modals/CreateChannel'),
        {},
        (channel) => {
          if( !!channel ) {
            this.populateChannels();
            this.group.platform_channel_id = channel.id;
          }
        }
      )
    },
    async save() {
      this.loading = true;

      if( this.isEdit === false ) {
        const resp = await this.$api.Groups.create(this.group)
        .then( (d) => {
          this.$message({
            type: 'success',
            message: `Group has been successfully created.`
          })

          this.loading = false;

          const isFirstGroup = this.team.settings.first_group !== true ? true : false;

          this.$store.dispatch('updateTeamSetting', {first_group: true});

          this.$router.replace({
            name: 'admin-groups',
            params: {
              forceRefresh: true,
              action: 'CREATE',
              isFirstGroup
            }
          })
        } )
        .catch( (e) => {
          this.loading = false;
          const error = e.response.data;

          if( error.type == 'GROUP_CHANNEL_CONFLICT' ) {
            this.$message({
              message: `A group using this channel already exists.`,
              type: 'error'
            })
          }
        } )

        
      }
      else {
        //save updates
        const resp = await this.$api.Groups.update(this.group.id, this.group)
        .then( (d) => {
          this.$message({
            type: 'success',
            message: `Group has been updated.`
          })

          this.loading = false;

          this.$router.replace({
            name: 'admin-groups',
            params: {
              forceRefresh: true
            }
          })
        } )
        .catch( (e) => {
          this.loading = false;
          const error = e.response.data;

          if( error.type == 'GROUP_CHANNEL_CONFLICT' ) {
            this.$message({
              message: `A group using this channel already exists.`,
              type: 'error'
            })
          }
        } )
      }
    },
    async populateChannels() {
      this.channelsLoading = true;
      await this.$store.dispatch('populateChannels')
      this.channelsLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.time-selector {
  display: flex;
  align-items: center;
  margin: 0 -5px;

  ::v-deep .el-select:first-child {
    flex: 0 0 100px;
  }

  ::v-deep .el-select:last-child {
    margin: 0 5px 0;
    flex: 1;
  }
}

.channel-selector {
  margin: 0 0 10px;
  display: flex;
  align-items: center;

  ::v-deep .el-select {
    margin: 0 10px 0 0;
    flex: 1;
  }

  button {
    flex: 0 0 28px;
  }
}
</style>